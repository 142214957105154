const firebaseConfig = {
    apiKey: "AIzaSyBlleuLWRwAsZOnjc2QqzBxbymOpxM7ONY",
    authDomain: "dreico-demo.firebaseapp.com",
    projectId: "dreico-demo",
    storageBucket: "dreico-demo.appspot.com",
    messagingSenderId: "288856447229",
    appId: "1:288856447229:web:b705a348d65b9bdb618694",
    measurementId: "G-DPS82ZCB68"
};

export default firebaseConfig